import { Card } from "antd";
import styled from "styled-components";

export const StyledNotification = styled(Card)`

background-color: #f6f5f7;

& h6{
color: #441f69;
}

& p{
color: #6e0e46
}

& span{
    color: #6e0e46;
}
`